var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./shadow.js.shim.module$crc_32.js");require("./clojure.set.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.ref.js");require("./metabase.mbql.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var AW,$ka,ala,IW,bla,cla,ela,EW,fla,dla;AW=function(a){return a+1};$CLJS.BW=function(a){if("string"===typeof a){var b=$CLJS.nh(/[+-]?\d+/,a);return $CLJS.n(b)?(a=parseInt(a),a<=Number.MAX_SAFE_INTEGER&&a>=Number.MIN_SAFE_INTEGER?a:null):b}throw Error($CLJS.hm(a));};
$ka=function(a){var b=new $CLJS.Ea,c=$CLJS.Cu;$CLJS.Cu=new $CLJS.sc(b);try{var d=$CLJS.Cu,e=$CLJS.Va($CLJS.Kv(d)),f=$CLJS.Cu;$CLJS.Cu=e?$CLJS.Bv(d):d;try{d=$CLJS.Mv;$CLJS.Mv=!0;try{$CLJS.Ov(a)}finally{$CLJS.Mv=d}$CLJS.E.g(0,$CLJS.Ju($CLJS.Cu,$CLJS.Lu))||$CLJS.ac($CLJS.Cu,"\n");$CLJS.Iu()}finally{$CLJS.Cu=f}$CLJS.uh($CLJS.p.h(b))}finally{$CLJS.Cu=c}};$CLJS.CW=function(a,b){return $CLJS.Se.j($CLJS.fa,a,b)};
$CLJS.DW=function(a){var b=$CLJS.yv;$CLJS.yv=120;try{var c=new $CLJS.Ea,d=$CLJS.Qa,e=$CLJS.Ra;$CLJS.Qa=!0;$CLJS.Ra=function(f){return c.append(f)};try{$ka(a)}finally{$CLJS.Ra=e,$CLJS.Qa=d}return $CLJS.p.h(c)}finally{$CLJS.yv=b}};
ala=function(a){a=$CLJS.O(a);var b=$CLJS.J.j(a,$CLJS.MV,$CLJS.Td),c=$CLJS.J.j(a,$CLJS.JV,function(f,k){return[$CLJS.p.h(f),"_",$CLJS.p.h(k)].join("")}),d=$CLJS.Xe($CLJS.N),e=$CLJS.Xe($CLJS.N);return function(){function f(m,t){for(;;){var u=b.h?b.h(t):b.call(null,t),v=function(){var z=new $CLJS.P(null,2,5,$CLJS.Q,[m,u],null),C=$CLJS.q(d);return C.h?C.h(z):C.call(null,z)}();if($CLJS.n(v))return v;var x=$CLJS.J.g($CLJS.Oh.v(e,$CLJS.Qk,u,$CLJS.ZD(AW,0)),u);if($CLJS.E.g(x,1))return $CLJS.Oh.v(d,$CLJS.R,
new $CLJS.P(null,2,5,$CLJS.Q,[m,u],null),t),t;t=function(){var z=t,C=$CLJS.p.h(x);return c.g?c.g(z,C):c.call(null,z,C)}()}}function k(m){var t=l,u=t.g;null==EW&&(EW=$CLJS.Xe(0));var v=$CLJS.Fh.h([$CLJS.p.h("G__"),$CLJS.p.h($CLJS.Oh.g(EW,AW))].join(""));return u.call(t,v,m)}var l=null;l=function(m,t){switch(arguments.length){case 1:return k.call(this,m);case 2:return f.call(this,m,t)}throw Error("Invalid arity: "+arguments.length);};l.h=k;l.g=f;return l}()};
$CLJS.FW=function(a){return $CLJS.zd(a)&&1<$CLJS.D(a)&&$CLJS.A(a)instanceof $CLJS.M&&$CLJS.xd($CLJS.hd(a))&&$CLJS.Hd($CLJS.hd(a),$CLJS.FE)};$CLJS.GW=function(a){return $CLJS.FW(a)?$CLJS.xE.h($CLJS.ME(a)):null};$CLJS.HW=function(a,b){return $CLJS.NE($CLJS.FW(a)?a:new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cj,new $CLJS.h(null,2,[$CLJS.FE,$CLJS.p.h($CLJS.PE()),$CLJS.Ei,$CLJS.TE(a)],null),a],null),$CLJS.R,$CLJS.H([$CLJS.xE,b]))};
IW=function(a,b,c){var d=$CLJS.Hd(a,b)?$CLJS.Qk.j(a,b,function(e){return $CLJS.zd(e)&&$CLJS.E.g($CLJS.A(e),$CLJS.Cs)?$CLJS.Df($CLJS.bf(1,e)):new $CLJS.P(null,1,5,$CLJS.Q,[e],null)}):a;return $CLJS.Hd(a,b)?$CLJS.HV(d,$CLJS.Ee([b,c])):d};bla=function(a){var b=$CLJS.cm(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mP,$CLJS.QQ],null));b=JW.h?JW.h(b):JW.call(null,b);return $CLJS.xW($CLJS.R.l(IW($CLJS.Fk.l(a,$CLJS.mP,$CLJS.H([$CLJS.QQ])),$CLJS.ZR,$CLJS.xL),$CLJS.cu,$CLJS.pL,$CLJS.H([$CLJS.uL,b])))};
cla=function(a){return $CLJS.Pk.g(bla,a)};ela=function(a){return $CLJS.n(a)?$CLJS.R.j($CLJS.Qk.j($CLJS.Kl(a)?new $CLJS.h(null,1,[$CLJS.KW,a],null):a,$CLJS.KW,function(b){return $CLJS.Pk.g(function(c){return $CLJS.R.j($CLJS.GA(c,$CLJS.gB),$CLJS.cu,$CLJS.TK)},b)}),$CLJS.cu,dla):null};
$CLJS.LW=function(a){if($CLJS.E.g($CLJS.cu.h(a),$CLJS.pW))return a;var b=$CLJS.lj.h(a);b=b instanceof $CLJS.M?b.T:null;switch(b){case "native":return $CLJS.el.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.cu,$CLJS.pW,$CLJS.uL,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.el.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.cu,$CLJS.jW],null),$CLJS.HV($CLJS.rQ.h(a),new $CLJS.h(null,1,[$CLJS.LF,$CLJS.rQ],null))]))],null)],null),$CLJS.Fk.l(a,$CLJS.lj,$CLJS.H([$CLJS.rQ]))]));case "query":return $CLJS.el.l($CLJS.H([new $CLJS.h(null,
2,[$CLJS.cu,$CLJS.pW,$CLJS.uL,JW($CLJS.LF.h(a))],null),$CLJS.Fk.l(a,$CLJS.lj,$CLJS.H([$CLJS.LF]))]));default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}};$CLJS.MW=function(a,b){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.uL);var c=0>b?$CLJS.D(a)+b:b;if(c>=$CLJS.D(a)||0>c)throw $CLJS.hi($CLJS.oE("Stage {0} does not exist",$CLJS.H([b])),new $CLJS.h(null,1,[fla,$CLJS.D(a)],null));return c};
$CLJS.NW=function(a,b){a=$CLJS.LW(a);a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.uL);return $CLJS.J.g($CLJS.Df(c),$CLJS.MW(a,b))};$CLJS.OW=function(a){return"string"===typeof a&&(a=$CLJS.oh(/^card__(\d+)$/,a),$CLJS.n(a))?($CLJS.I(a,0,null),a=$CLJS.I(a,1,null),$CLJS.BW(a)):null};$CLJS.QW=function(){return $CLJS.Nk.g(PW,ala($CLJS.H([$CLJS.MV,$CLJS.cB,$CLJS.JV,function(a,b){return PW.h([$CLJS.p.h(a),"_",$CLJS.p.h(b)].join(""))}])))};EW=null;
$CLJS.RW=new $CLJS.M("metabase.lib.field","temporal-unit","metabase.lib.field/temporal-unit",602312727);$CLJS.SW=new $CLJS.M("metabase.lib.util","cannot-remove-final-join-condition","metabase.lib.util/cannot-remove-final-join-condition",-470911224);$CLJS.TW=new $CLJS.M(null,"join","join",-758861890);$CLJS.UW=new $CLJS.M(null,"stage","stage",1843544772);fla=new $CLJS.M(null,"num-stages","num-stages",1426797535);$CLJS.KW=new $CLJS.M(null,"columns","columns",1998437288);
$CLJS.VW=new $CLJS.M("metabase.lib.field","original-effective-type","metabase.lib.field/original-effective-type",34164372);$CLJS.WW=new $CLJS.M(null,"table","table",-564943036);dla=new $CLJS.M("metadata","results","metadata/results",330329298);$CLJS.XW=new $CLJS.M("metabase.lib.field","binning","metabase.lib.field/binning",-222751823);var JW,PW;
JW=function JW(a){a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.QQ),d=$CLJS.J.g(a,$CLJS.ZO);c=$CLJS.n(c)?JW.h?JW.h(c):JW.call(null,c):$CLJS.Cf;d=ela(d);var e=$CLJS.D(c)-1;c=$CLJS.n($CLJS.n(d)?!(0>e):d)?$CLJS.dM(c,new $CLJS.P(null,2,5,$CLJS.Q,[e,$CLJS.vV],null),d):c;d=$CLJS.n($CLJS.rQ.h(a))?$CLJS.jW:$CLJS.cW;a=$CLJS.el.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.cu,d],null),$CLJS.Fk.l(a,$CLJS.QQ,$CLJS.H([$CLJS.ZO]))]));a=$CLJS.y($CLJS.LN.h(a))?$CLJS.Qk.j(a,$CLJS.LN,cla):a;a=IW(a,$CLJS.TF,$CLJS.qW);return $CLJS.be.g(c,a)};
$CLJS.YW=function(){function a(c,d,e,f){var k=null;if(3<arguments.length){k=0;for(var l=Array(arguments.length-3);k<l.length;)l[k]=arguments[k+3],++k;k=new $CLJS.w(l,0,null)}return b.call(this,c,d,e,k)}function b(c,d,e,f){c=$CLJS.LW(c);c=$CLJS.O(c);var k=$CLJS.J.g(c,$CLJS.uL);d=$CLJS.MW(c,d);e=$CLJS.Se.N($CLJS.Qk,$CLJS.Df(k),d,e,f);return $CLJS.R.j(c,$CLJS.uL,e)}a.A=3;a.B=function(c){var d=$CLJS.A(c);c=$CLJS.B(c);var e=$CLJS.A(c);c=$CLJS.B(c);var f=$CLJS.A(c);c=$CLJS.Lc(c);return b(d,e,f,c)};a.l=
b;return a}();
PW=function(){function a(d,e){if((new TextEncoder).encode(d).length<=e)return d;var f;a:for(f=($CLJS.vW.str(d,0)>>>0).toString(16);;)if(8>$CLJS.D(f))f=["0",$CLJS.p.h(f)].join("");else break a;e=new Uint8Array(e-9);e=(new TextEncoder).encodeInto(d,e);return[d.substring(0,e.read),"_",$CLJS.p.h(f)].join("")}function b(d){return c.g?c.g(d,60):c.call(null,d,60)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);
};c.h=b;c.g=a;return c}();$CLJS.ZW=RegExp(" id$","i");