var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.metadata.protocols.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.util.malli.registry.js");
'use strict';var DZ,Lma,Mma,Nma,Oma,Pma,JZ,Qma,Rma,Sma,MZ,Tma,Uma,Vma,RZ,SZ,Wma,Xma,Yma,a_,Zma,$ma,ana,d_,bna,cna,dna,ena;DZ=function(){};$CLJS.EZ=function(a){return null!=a?$CLJS.Bc===a.ki?!0:a.Wc?!1:$CLJS.$a(DZ,a):$CLJS.$a(DZ,a)};Lma=new $CLJS.r(null,"metabase.lib.metadata.protocols","metabase.lib.metadata.protocols",128660233,null);$CLJS.FZ=new $CLJS.M("lib","source-column-alias","lib/source-column-alias",1494821386);
Mma=new $CLJS.M("metabase.lib.schema.metadata","database","metabase.lib.schema.metadata/database",270611124);Nma=new $CLJS.M("lib","persisted-info","lib/persisted-info",-118569625);$CLJS.GZ=new $CLJS.M("source","previous-stage","source/previous-stage",1962610878);$CLJS.HZ=new $CLJS.M(null,"database-id","database-id",1883826326);Oma=new $CLJS.r("metabase.lib.metadata.protocols","metadata-provider?","metabase.lib.metadata.protocols/metadata-provider?",-472923865,null);
$CLJS.IZ=new $CLJS.M("source","table-defaults","source/table-defaults",909975194);Pma=new $CLJS.M(null,"human-readable-values","human-readable-values",-624842907);JZ=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.internal","metabase.lib.schema.metadata/column.remapping.internal",-285151559);Qma=new $CLJS.M("metabase.lib.schema.metadata","metadata-providerable","metabase.lib.schema.metadata/metadata-providerable",1520216538);Rma=new $CLJS.M(null,"dbms-version","dbms-version",-69238036);
Sma=new $CLJS.M(null,"field-name","field-name",1300687948);$CLJS.KZ=new $CLJS.M("metabase.lib.schema.metadata","column","metabase.lib.schema.metadata/column",-570344650);$CLJS.LZ=new $CLJS.M(null,"dataset","dataset",1159262238);MZ=new $CLJS.M("metabase.lib.schema.metadata","persisted-info","metabase.lib.schema.metadata/persisted-info",1457716669);Tma=new $CLJS.M(null,"field-definitions","field-definitions",1650155116);$CLJS.NZ=new $CLJS.M("source","expressions","source/expressions",-458367840);
$CLJS.OZ=new $CLJS.M(null,"definition","definition",-1198729982);Uma=new $CLJS.M(null,"state","state",-1988618099);$CLJS.PZ=new $CLJS.M("source","implicitly-joinable","source/implicitly-joinable",-729101197);Vma=new $CLJS.M(null,"is-audit","is-audit",327058420);$CLJS.QZ=new $CLJS.M("source","joins","source/joins",1225821486);RZ=new $CLJS.M("metabase.lib.schema.metadata","column-source","metabase.lib.schema.metadata/column-source",1071627828);
SZ=new $CLJS.M("metabase.lib.schema.metadata","metadata-provider","metabase.lib.schema.metadata/metadata-provider",-1514728827);$CLJS.TZ=new $CLJS.M("lib","desired-column-alias","lib/desired-column-alias",-1998967402);$CLJS.UZ=new $CLJS.M(null,"fk-target-field-id","fk-target-field-id",2019750287);Wma=new $CLJS.M("lib","external-remap","lib/external-remap",543358316);$CLJS.VZ=new $CLJS.M(null,"selected?","selected?",-742502788);
Xma=new $CLJS.M("metabase.lib.schema.metadata","segment","metabase.lib.schema.metadata/segment",-393440267);Yma=new $CLJS.M("metabase.lib.schema.metadata","metric","metabase.lib.schema.metadata/metric",1984974682);$CLJS.WZ=new $CLJS.M(null,"table-name","table-name",-2117866341);$CLJS.XZ=new $CLJS.M("source","card","source/card",-139977973);$CLJS.YZ=new $CLJS.M(null,"active","active",1895962068);$CLJS.ZZ=new $CLJS.M(null,"dataset-query","dataset-query",1851066427);
$CLJS.$Z=new $CLJS.M(null,"result-metadata","result-metadata",2017934672);a_=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.external","metabase.lib.schema.metadata/column.remapping.external",304054297);Zma=new $CLJS.M(null,"details","details",1956795411);$CLJS.b_=new $CLJS.M("source","fields","source/fields",-649667981);$ma=new $CLJS.M("metabase.lib.schema.metadata","card","metabase.lib.schema.metadata/card",147726027);$CLJS.c_=new $CLJS.M("source","native","source/native",-1444604147);
ana=new $CLJS.M("metabase.lib.schema.metadata","table","metabase.lib.schema.metadata/table",-2128230385);d_=new $CLJS.M("metabase.lib.schema.metadata","persisted-info.definition","metabase.lib.schema.metadata/persisted-info.definition",496848961);$CLJS.e_=new $CLJS.M("lib","card-id","lib/card-id",-1770167062);bna=new $CLJS.M("metadata.column.remapping","internal","metadata.column.remapping/internal",-1077920983);cna=new $CLJS.r(null,"metadata-provider?","metadata-provider?",1669871524,null);
dna=new $CLJS.M("lib","internal-remap","lib/internal-remap",-220033801);ena=new $CLJS.M("metadata.column.remapping","external","metadata.column.remapping/external",-1813287755);$CLJS.f_=new $CLJS.M(null,"source-alias","source-alias",1652088724);$CLJS.g_=new $CLJS.M(null,"engine","engine",1459054265);$CLJS.X(RZ,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.ss,$CLJS.XZ,$CLJS.c_,$CLJS.GZ,$CLJS.IZ,$CLJS.b_,$CLJS.nL,$CLJS.hL,$CLJS.QZ,$CLJS.NZ,$CLJS.PZ],null));$CLJS.X(a_,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yr,ena],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yi,$CLJS.aL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.yE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bL,$CLJS.lL],null)],null));
$CLJS.X(JZ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yr,bna],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yi,$CLJS.aL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.yE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ht,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yj,$CLJS.Ms],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[Pma,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yj,$CLJS.Ms],null)],null)],null));
$CLJS.X($CLJS.KZ,new $CLJS.P(null,19,5,$CLJS.Q,[$CLJS.gk,new $CLJS.h(null,1,[$CLJS.vt,"Valid column metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yr,$CLJS.TK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.Xj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wB,$CLJS.HE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Yi,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),$CLJS.lL],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tE,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.us,$CLJS.Xj],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ei,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.HE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eL,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.lL],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UZ,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.lL],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.f_,
new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.yE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xE,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.yE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VK,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RZ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.e_,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.cL],
null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FZ,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.yE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TZ,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xj,new $CLJS.h(null,2,[$CLJS.Xn,1,$CLJS.hk,60],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VZ,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),$CLJS.Ls],null),new $CLJS.P(null,3,5,$CLJS.Q,
[Wma,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,a_],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[dna,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JZ],null)],null)],null)],null));
$CLJS.X(d_,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WZ,$CLJS.yE],null),new $CLJS.P(null,2,5,$CLJS.Q,[Tma,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,2,5,$CLJS.Q,[Sma,$CLJS.yE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wB,$CLJS.HE],null)],null)],null)],null)],null)],null));
$CLJS.X(MZ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YZ,$CLJS.Ls],null),new $CLJS.P(null,2,5,$CLJS.Q,[Uma,$CLJS.yE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WZ,$CLJS.yE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OZ,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,d_],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SK,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,
$CLJS.yE],null)],null)],null));
$CLJS.X($ma,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.gk,new $CLJS.h(null,1,[$CLJS.vt,"Valid Card metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yr,$CLJS.mL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yi,$CLJS.cL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.yE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.HZ,$CLJS.dL],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZZ,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),$CLJS.gk],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$Z,
new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yj,$CLJS.gk],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LZ,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),$CLJS.Ls],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fL,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.YK],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Nma,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,MZ],null)],null)],null)],null));
$CLJS.X(Xma,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.gk,new $CLJS.h(null,1,[$CLJS.vt,"Valid Segment metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yr,$CLJS.XK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yi,$CLJS.WK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.yE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fL,$CLJS.YK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.OZ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.gk],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.Pt,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.yE],null)],null)],null));
$CLJS.X(Yma,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.gk,new $CLJS.h(null,1,[$CLJS.vt,"Valid Metric metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yr,$CLJS.ZK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yi,$CLJS.UK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.yE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fL,$CLJS.YK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.OZ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.gk],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.Pt,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.yE],null)],null)],null));
$CLJS.X(ana,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.gk,new $CLJS.h(null,1,[$CLJS.vt,"Valid Table metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yr,$CLJS.$K],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yi,$CLJS.YK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.yE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tE,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.yE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ol,new $CLJS.h(null,
1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.yE],null)],null)],null));
$CLJS.X(Mma,new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.gk,new $CLJS.h(null,1,[$CLJS.vt,"Valid Database metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yr,$CLJS.jL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yi,$CLJS.dL],null),new $CLJS.P(null,3,5,$CLJS.Q,[Rma,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.gk],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Zma,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),$CLJS.gk],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.g_,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),$CLJS.Wi],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kA,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,$CLJS.Wi],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Vma,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),$CLJS.Ls],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gL,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.gk],null)],null)],null));
$CLJS.X(SZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.vt,"Valid MetadataProvider"],null),new $CLJS.Gc(function(){return $CLJS.EZ},Oma,$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.nk,$CLJS.ui,$CLJS.Si,$CLJS.mj,$CLJS.wk,$CLJS.ti,$CLJS.ik,$CLJS.mk],[Lma,cna,"metabase/lib/metadata/protocols.cljc",25,1,78,78,$CLJS.U(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Wq],null)),"Whether `x` is a valid [[MetadataProvider]].",$CLJS.n($CLJS.EZ)?$CLJS.EZ.H:null]))],null));
$CLJS.X(Qma,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SZ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gk,new $CLJS.h(null,1,[$CLJS.vt,"map with a MetadataProvider in the key :lib/metadata (i.e. a query)"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SZ],null)],null)],null)],null));