var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var ffa,gfa,hfa,ifa,jfa,kfa,lfa,UG,mfa,nfa,ofa,pfa,qfa,rfa,sfa,tfa,WG,ufa;$CLJS.PG=new $CLJS.M(null,"supported-field","supported-field",-2061545519);ffa=new $CLJS.M("mbql.clause","distinct","mbql.clause/distinct",843025748);$CLJS.QG=new $CLJS.M("metabase.lib.schema.aggregation","aggregations","metabase.lib.schema.aggregation/aggregations",-203649172);gfa=new $CLJS.M("mbql.clause","count-where","mbql.clause/count-where",2010666417);
hfa=new $CLJS.M("mbql.clause","sum-where","mbql.clause/sum-where",-1599647617);$CLJS.RG=new $CLJS.M(null,"requires-column?","requires-column?",1623131448);$CLJS.SG=new $CLJS.M(null,"column-name","column-name",551523580);ifa=new $CLJS.M("metabase.lib.schema.aggregation","operator","metabase.lib.schema.aggregation/operator",2070038764);$CLJS.TG=new $CLJS.M(null,"display-info","display-info",-816930907);jfa=new $CLJS.M("mbql.clause","max","mbql.clause/max",1615385533);
kfa=new $CLJS.M("mbql.clause","cum-count","mbql.clause/cum-count",-1408720642);lfa=new $CLJS.M("mbql.clause","median","mbql.clause/median",-1026386338);UG=new $CLJS.M("metabase.lib.schema.aggregation","percentile.percentile","metabase.lib.schema.aggregation/percentile.percentile",-144637711);mfa=new $CLJS.M("mbql.clause","min","mbql.clause/min",2074448531);nfa=new $CLJS.M("mbql.clause","percentile","mbql.clause/percentile",2064253651);ofa=new $CLJS.M("mbql.clause","stddev","mbql.clause/stddev",659381076);
pfa=new $CLJS.M("mbql.clause","var","mbql.clause/var",809434920);qfa=new $CLJS.M("mbql.clause","share","mbql.clause/share",-37921592);rfa=new $CLJS.M("mbql.clause","count","mbql.clause/count",-454225910);sfa=new $CLJS.M("mbql.clause","cum-sum","mbql.clause/cum-sum",468410018);$CLJS.VG=new $CLJS.M(null,"driver-feature","driver-feature",1905324248);tfa=new $CLJS.M("mbql.clause","avg","mbql.clause/avg",1826863173);
WG=new $CLJS.M("metabase.lib.schema.aggregation","aggregation","metabase.lib.schema.aggregation/aggregation",-736862750);$CLJS.XG=new $CLJS.M("operator","aggregation","operator/aggregation",-1961510924);ufa=new $CLJS.M("mbql.clause","sum","mbql.clause/sum",1691012107);$CLJS.FF($CLJS.Wx,$CLJS.H([$CLJS.ot,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tB,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dF],null)],null)],null)],null)]));$CLJS.FF($CLJS.pG,$CLJS.H([$CLJS.ot,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tB,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dF],null)],null)],null)],null)]));
$CLJS.DF($CLJS.iG,$CLJS.H([$CLJS.ot,$CLJS.UD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.oF],null)],null)]));$CLJS.DF($CLJS.UF,$CLJS.H([$CLJS.ot,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dF],null)],null)]));$CLJS.DF($CLJS.xG,$CLJS.H([$CLJS.ot,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cF],null)],null)]));
$CLJS.DF($CLJS.hk,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jF],null)],null)]));$CLJS.wF($CLJS.hk,$CLJS.iF);$CLJS.DF($CLJS.CG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.oF],null)],null)]));$CLJS.wF($CLJS.CG,$CLJS.iF);$CLJS.DF($CLJS.Xn,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jF],null)],null)]));$CLJS.wF($CLJS.Xn,$CLJS.iF);
$CLJS.X(UG,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.h(null,1,[$CLJS.vt,"valid percentile"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.oF],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.vt,"percentile must be between zero and one"],null),function(a){return 0<=a&&1>=a}],null)],null));$CLJS.DF($CLJS.$F,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.oF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UG],null)]));$CLJS.wF($CLJS.$F,$CLJS.iF);
$CLJS.DF($CLJS.vG,$CLJS.H([$CLJS.ot,$CLJS.UD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cF],null)],null)]));$CLJS.DF($CLJS.DG,$CLJS.H([$CLJS.ot,$CLJS.UD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.oF],null)],null)]));$CLJS.DF($CLJS.BG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.oF],null)],null)]));
$CLJS.DF($CLJS.KG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.oF],null)],null)]));$CLJS.wF($CLJS.BG,$CLJS.iF);$CLJS.wF($CLJS.KG,$CLJS.iF);$CLJS.DF($CLJS.IG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.oF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cF],null)],null)]));$CLJS.wF($CLJS.IG,$CLJS.iF);
$CLJS.DF($CLJS.VF,$CLJS.H([$CLJS.ot,$CLJS.UD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.oF],null)],null)]));$CLJS.X(WG,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.vs,tfa,rfa,kfa,gfa,ffa,jfa,lfa,mfa,nfa,qfa,ofa,ufa,sfa,hfa,pfa,$CLJS.Bl],null));$CLJS.X($CLJS.QG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yj,new $CLJS.h(null,1,[$CLJS.Xn,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WG],null)],null));
$CLJS.YG=new $CLJS.P(null,10,5,$CLJS.Q,[new $CLJS.h(null,4,[$CLJS.hG,$CLJS.Wx,$CLJS.RG,!1,$CLJS.VG,$CLJS.JF,$CLJS.TG,function(){return new $CLJS.h(null,3,[$CLJS.tE,$CLJS.QE("Count of rows"),$CLJS.SG,$CLJS.QE("Count"),$CLJS.Pt,$CLJS.QE("Total number of rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.hG,$CLJS.BG,$CLJS.PG,$CLJS.dk,$CLJS.RG,!0,$CLJS.VG,$CLJS.JF,$CLJS.TG,function(){return new $CLJS.h(null,3,[$CLJS.tE,$CLJS.QE("Sum of ..."),$CLJS.SG,$CLJS.QE("Sum"),$CLJS.Pt,$CLJS.QE("Sum of all the values of a column.")],
null)}],null),new $CLJS.h(null,5,[$CLJS.hG,$CLJS.iG,$CLJS.PG,$CLJS.dk,$CLJS.RG,!0,$CLJS.VG,$CLJS.JF,$CLJS.TG,function(){return new $CLJS.h(null,3,[$CLJS.tE,$CLJS.QE("Average of ..."),$CLJS.SG,$CLJS.QE("Average"),$CLJS.Pt,$CLJS.QE("Average of all the values of a column")],null)}],null),new $CLJS.h(null,5,[$CLJS.hG,$CLJS.CG,$CLJS.PG,$CLJS.dk,$CLJS.RG,!0,$CLJS.VG,$CLJS.kG,$CLJS.TG,function(){return new $CLJS.h(null,3,[$CLJS.tE,$CLJS.QE("Median of ..."),$CLJS.SG,$CLJS.QE("Median"),$CLJS.Pt,$CLJS.QE("Median of all the values of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.hG,$CLJS.UF,$CLJS.PG,$CLJS.Ms,$CLJS.RG,!0,$CLJS.VG,$CLJS.JF,$CLJS.TG,function(){return new $CLJS.h(null,3,[$CLJS.tE,$CLJS.QE("Number of distinct values of ..."),$CLJS.SG,$CLJS.QE("Distinct values"),$CLJS.Pt,$CLJS.QE("Number of unique values of a column among all the rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.hG,$CLJS.KG,$CLJS.PG,$CLJS.dk,$CLJS.RG,!0,$CLJS.VG,$CLJS.JF,$CLJS.TG,function(){return new $CLJS.h(null,3,[$CLJS.tE,$CLJS.QE("Cumulative sum of ..."),
$CLJS.SG,$CLJS.QE("Sum"),$CLJS.Pt,$CLJS.QE("Additive sum of all the values of a column.\ne.x. total revenue over time.")],null)}],null),new $CLJS.h(null,4,[$CLJS.hG,$CLJS.pG,$CLJS.RG,!1,$CLJS.VG,$CLJS.JF,$CLJS.TG,function(){return new $CLJS.h(null,3,[$CLJS.tE,$CLJS.QE("Cumulative count of rows"),$CLJS.SG,$CLJS.QE("Count"),$CLJS.Pt,$CLJS.QE("Additive count of the number of rows.\ne.x. total number of sales over time.")],null)}],null),new $CLJS.h(null,5,[$CLJS.hG,$CLJS.DG,$CLJS.PG,$CLJS.dk,$CLJS.RG,
!0,$CLJS.VG,$CLJS.yG,$CLJS.TG,function(){return new $CLJS.h(null,3,[$CLJS.tE,$CLJS.QE("Standard deviation of ..."),$CLJS.SG,$CLJS.QE("SD"),$CLJS.Pt,$CLJS.QE("Number which expresses how much the values of a column vary among all rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.hG,$CLJS.Xn,$CLJS.PG,$CLJS.Xi,$CLJS.RG,!0,$CLJS.VG,$CLJS.JF,$CLJS.TG,function(){return new $CLJS.h(null,3,[$CLJS.tE,$CLJS.QE("Minimum of ..."),$CLJS.SG,$CLJS.QE("Min"),$CLJS.Pt,$CLJS.QE("Minimum value of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.hG,$CLJS.hk,$CLJS.PG,$CLJS.Xi,$CLJS.RG,!0,$CLJS.VG,$CLJS.JF,$CLJS.TG,function(){return new $CLJS.h(null,3,[$CLJS.tE,$CLJS.QE("Maximum of ..."),$CLJS.SG,$CLJS.QE("Max"),$CLJS.Pt,$CLJS.QE("Maximum value of a column")],null)}],null)],null);
$CLJS.X(ifa,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yr,$CLJS.XG],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hG,$CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ss],null),$CLJS.hf.h($CLJS.hG),$CLJS.YG)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PG,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.Wi],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RG,$CLJS.Ls],null),new $CLJS.P(null,2,5,
$CLJS.Q,[$CLJS.VG,$CLJS.Wi],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TG,$CLJS.md],null)],null));