var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.shared.util.internal.time_common.js");require("./metabase.util.malli.registry.js");require("./shadow.js.shim.module$moment.js");require("./shadow.js.shim.module$moment_timezone.js");
'use strict';var UI,WI,YI,jJ,nJ,vJ;$CLJS.TI=new $CLJS.M(null,"get-month","get-month",-369374731);UI=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.options","metabase.lib.schema.expression.temporal/absolute-datetime.options",-925669633);$CLJS.VI=new $CLJS.M(null,"convert-timezone","convert-timezone",-124803001);WI=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.unit","metabase.lib.schema.expression.temporal/temporal-extract.unit",339336486);
$CLJS.XI=new $CLJS.M(null,"iso","iso",-1366207543);YI=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.base-type","metabase.lib.schema.expression.temporal/absolute-datetime.base-type",-904703365);$CLJS.ZI=new $CLJS.M(null,"get-second","get-second",-2065946318);$CLJS.$I=new $CLJS.M(null,"get-year","get-year",-936011274);$CLJS.aJ=new $CLJS.M(null,"absolute-datetime","absolute-datetime",-560340465);
$CLJS.bJ=new $CLJS.M("lib.type-of","type-is-temporal-type-of-first-arg","lib.type-of/type-is-temporal-type-of-first-arg",76272405);$CLJS.cJ=new $CLJS.M(null,"week-of-year-iso","week-of-year-iso",-1827744293);$CLJS.dJ=new $CLJS.M(null,"datetime-diff","datetime-diff",1133112155);$CLJS.eJ=new $CLJS.M(null,"interval","interval",1708495417);$CLJS.fJ=new $CLJS.M(null,"us","us",746429226);$CLJS.gJ=new $CLJS.M(null,"relative-datetime","relative-datetime",1285169278);
$CLJS.hJ=new $CLJS.M(null,"datetime","datetime",494675702);$CLJS.iJ=new $CLJS.M(null,"now","now",-1650525531);jJ=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.week-mode","metabase.lib.schema.expression.temporal/temporal-extract.week-mode",430590916);$CLJS.kJ=new $CLJS.M(null,"instance","instance",-2121349050);$CLJS.lJ=new $CLJS.M(null,"get-day","get-day",127568857);$CLJS.mJ=new $CLJS.M(null,"get-week","get-week",-888059349);
nJ=new $CLJS.M("metabase.lib.schema.expression.temporal","relative-datetime.amount","metabase.lib.schema.expression.temporal/relative-datetime.amount",7216442);$CLJS.oJ=new $CLJS.M(null,"datetime-add","datetime-add",209603411);$CLJS.pJ=new $CLJS.M(null,"get-minute","get-minute",1468086343);$CLJS.qJ=new $CLJS.M(null,"get-day-of-week","get-day-of-week",2010140752);$CLJS.rJ=new $CLJS.M("metabase.lib.schema.expression.temporal","timezone-id","metabase.lib.schema.expression.temporal/timezone-id",-1909635091);
$CLJS.sJ=new $CLJS.M(null,"temporal-extract","temporal-extract",1781686405);$CLJS.tJ=new $CLJS.M(null,"get-hour","get-hour",622714059);$CLJS.uJ=new $CLJS.M(null,"datetime-subtract","datetime-subtract",275307697);vJ=new $CLJS.M("metabase.lib.schema.expression.temporal","get-week-mode","metabase.lib.schema.expression.temporal/get-week-mode",1489967652);$CLJS.wJ=new $CLJS.M(null,"get-quarter","get-quarter",1327778593);$CLJS.xJ=new $CLJS.M(null,"week-of-year-us","week-of-year-us",849423511);
$CLJS.yJ=new $CLJS.M(null,"week-of-year-instance","week-of-year-instance",2047974624);$CLJS.zJ=new $CLJS.M(null,"target","target",253001721);$CLJS.DF($CLJS.eJ,$CLJS.H([$CLJS.ot,$CLJS.iD,$CLJS.Hs,$CLJS.iH]));$CLJS.SE.m(null,$CLJS.bJ,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.TE(a);return $CLJS.vd(a)?(a=$CLJS.lu.g(a,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.YC,null,$CLJS.xD,null],null),null)),$CLJS.E.g($CLJS.D(a),1)?$CLJS.A(a):a):a});$CLJS.wF($CLJS.bJ,$CLJS.iF);
for(var AJ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oJ,$CLJS.uJ],null)),BJ=null,CJ=0,DJ=0;;)if(DJ<CJ){var EJ=BJ.X(null,DJ);$CLJS.DF(EJ,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aF],null),$CLJS.Hs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iH],null)]));$CLJS.wF(EJ,$CLJS.bJ);DJ+=1}else{var FJ=$CLJS.y(AJ);if(FJ){var GJ=FJ;if($CLJS.Ad(GJ)){var HJ=$CLJS.lc(GJ),Nga=$CLJS.mc(GJ),Oga=HJ,Pga=$CLJS.D(HJ);AJ=Nga;BJ=Oga;CJ=Pga}else{var IJ=$CLJS.A(GJ);$CLJS.DF(IJ,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.W,$CLJS.aF],null),$CLJS.Hs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iH],null)]));$CLJS.wF(IJ,$CLJS.bJ);AJ=$CLJS.B(GJ);BJ=null;CJ=0}DJ=0}else break}
for(var JJ=$CLJS.y(new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.$I,$CLJS.TI,$CLJS.lJ,$CLJS.tJ,$CLJS.pJ,$CLJS.ZI,$CLJS.wJ],null)),KJ=null,LJ=0,MJ=0;;)if(MJ<LJ){var Qga=KJ.X(null,MJ);$CLJS.DF(Qga,$CLJS.H([$CLJS.ot,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aF],null)],null)]));MJ+=1}else{var NJ=$CLJS.y(JJ);if(NJ){var OJ=NJ;if($CLJS.Ad(OJ)){var PJ=$CLJS.lc(OJ),Rga=$CLJS.mc(OJ),Sga=PJ,Tga=$CLJS.D(PJ);JJ=Rga;KJ=Sga;LJ=Tga}else{var Uga=$CLJS.A(OJ);$CLJS.DF(Uga,$CLJS.H([$CLJS.ot,
$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aF],null)],null)]));JJ=$CLJS.B(OJ);KJ=null;LJ=0}MJ=0}else break}$CLJS.DF($CLJS.dJ,$CLJS.H([$CLJS.ot,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fH],null)]));
for(var QJ=$CLJS.y(new $CLJS.ah(null,new $CLJS.h(null,8,[$CLJS.wJ,null,$CLJS.pJ,null,$CLJS.tJ,null,$CLJS.qJ,null,$CLJS.ZI,null,$CLJS.TI,null,$CLJS.$I,null,$CLJS.lJ,null],null),null)),RJ=null,SJ=0,TJ=0;;)if(TJ<SJ){var Vga=RJ.X(null,TJ);$CLJS.DF(Vga,$CLJS.H([$CLJS.ot,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aF],null)],null)]));TJ+=1}else{var UJ=$CLJS.y(QJ);if(UJ){var VJ=UJ;if($CLJS.Ad(VJ)){var WJ=$CLJS.lc(VJ),Wga=$CLJS.mc(VJ),Xga=WJ,Yga=$CLJS.D(WJ);
QJ=Wga;RJ=Xga;SJ=Yga}else{var Zga=$CLJS.A(VJ);$CLJS.DF(Zga,$CLJS.H([$CLJS.ot,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aF],null)],null)]));QJ=$CLJS.B(VJ);RJ=null;SJ=0}TJ=0}else break}$CLJS.X(vJ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ss,$CLJS.XI,$CLJS.fJ,$CLJS.kJ],null));
$CLJS.FF($CLJS.mJ,$CLJS.H([$CLJS.ot,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aF],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vJ],null)],null)],null)],null)]));
$CLJS.X($CLJS.rJ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,$CLJS.yE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vs,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ss,new $CLJS.h(null,2,[$CLJS.vt,"valid timezone ID",$CLJS.xt,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.Cj);return["invalid timezone ID: ",$CLJS.Mh.l($CLJS.H([a]))].join("")}],null)],null),$CLJS.$G($CLJS.Jd,$CLJS.SI.tz.names())),$CLJS.JI],null)],null));
$CLJS.FF($CLJS.VI,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aF],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rJ],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eA,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rJ],null)],null)],null)],
null)]));$CLJS.wF($CLJS.VI,$CLJS.bJ);$CLJS.DF($CLJS.iJ,$CLJS.H([$CLJS.ot,$CLJS.aD]));$CLJS.X(YI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.HE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.vt,":absolute-datetime base-type must derive from :type/Date or :type/DateTime"],null),function(a){return $CLJS.Re(function(b){return $CLJS.xC(a,b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YC,$CLJS.xD],null))}],null)],null));
$CLJS.X(UI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ut,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.DE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wB,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YI],null)],null)],null)],null));
$CLJS.OG.g($CLJS.aJ,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.js,new $CLJS.h(null,1,[$CLJS.vt,"valid :absolute-datetime clause"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yr,$CLJS.aJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UI],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ls,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.js,new $CLJS.h(null,1,[$CLJS.vt,":absolute-datetime literal and unit for :type/Date"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.NI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.PI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.HI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yr,$CLJS.ci],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hH],null)],null)],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.js,new $CLJS.h(null,1,[$CLJS.vt,":absolute-datetime literal and unit for :type/DateTime"],
null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yr,$CLJS.Yv],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.RI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yr,$CLJS.ci],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cH],null)],null)],null)],null)],null)],null));
$CLJS.SE.m(null,$CLJS.aJ,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);a=$CLJS.E.g(b,$CLJS.Yv)?$CLJS.E.g(a,$CLJS.ci)?$CLJS.xD:$CLJS.n($CLJS.vF($CLJS.hH,a))?$CLJS.YC:$CLJS.xD:null;if($CLJS.n(a))return a;a="string"===typeof b?$CLJS.n($CLJS.nh($CLJS.EI,b))?$CLJS.YC:$CLJS.n($CLJS.nh($CLJS.FI,b))?$CLJS.YC:null:null;if($CLJS.n(a))return a;b=$CLJS.TE(b);b=$CLJS.vd(b)?$CLJS.eg.j($CLJS.bh,$CLJS.kf(function(c){return $CLJS.xC(c,$CLJS.Uj)}),b):b;return $CLJS.vd(b)&&
$CLJS.E.g($CLJS.D(b),1)?$CLJS.A(b):b});$CLJS.X(nJ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yr,$CLJS.Yv],null),$CLJS.Hs],null));
$CLJS.FF($CLJS.gJ,$CLJS.H([$CLJS.ot,$CLJS.xD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nJ],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ri,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iH],null)],null)],null)],null)]));
$CLJS.DF($CLJS.Ky,$CLJS.H([$CLJS.ot,$CLJS.XD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gH],null)]));$CLJS.X(WI,new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.ss,$CLJS.eH,$CLJS.zi,$CLJS.kk,$CLJS.cJ,$CLJS.xJ,$CLJS.yJ,$CLJS.Ej,$CLJS.Fi,$CLJS.vi,$CLJS.hj,$CLJS.bH],null));$CLJS.X(jJ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ss,$CLJS.XI,$CLJS.fJ,$CLJS.kJ],null));
$CLJS.FF($CLJS.sJ,$CLJS.H([$CLJS.ot,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aF],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ri,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jJ],null)],null)],
null)],null)]));